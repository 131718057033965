import React from 'react';
import Layout from '../components/layout';

const ImprintPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header>
        <h2>Impressum</h2>
        <p>
          Offenlegungspflicht gemäß § 25 MedienG und Informationspflicht gemäß
          E-Commerce-Gesetz
        </p>
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <table>
            <thead>
              <tr>
                <th>Medieninhaber und Herausgeber</th>
                <th>Fachhochschule Salzburg GmbH</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Adresse</td>
                <td>Urstein S&uuml;d 1</td>
              </tr>
              <tr>
                <td>A-5412 Puch/Salzburg</td>
              </tr>
              <tr>
                <td>&Ouml;sterreich</td>
              </tr>
              <tr>
                <td>Telefon</td>
                <td>
                  <a href="tel:00435022110">+43-(0)50-2211-0</a>
                </td>
              </tr>
              <tr>
                <td>Fax</td>
                <td>
                  <a href="fax:00435022111099">+43-(0)50-2211-1099</a>
                </td>
              </tr>
              <tr>
                <td>E-Mail</td>
                <td>
                  <a href="mailto:press@fh-salzburg.ac.at">
                    press@fh-salzburg.ac.at
                  </a>
                </td>
              </tr>
              <tr>
                <td>Web</td>
                <td>
                  <a href="http://www.fh-salzburg.ac.at">fh-salzburg.ac.at</a>
                </td>
              </tr>
              <tr>
                <td>UID</td>
                <td>ATU 44554503</td>
              </tr>
              <tr>
                <td>Firmenbuchnummer</td>
                <td>FN 166054y</td>
              </tr>
              <tr>
                <td>Firmenbuchgericht</td>
                <td>Landesgericht Salzburg</td>
              </tr>
              <tr>
                <td>Gesch&auml;ftsf&uuml;hrung</td>
                <td>FH-Prof. Priv.-Doz. Dr. Dominik Engel</td>
              </tr>
              <tr>
                <td>DVR Nummer</td>
                <td>0946281</td>
              </tr>
            </tbody>
          </table>
          <p>F&uuml;r den Inhalt der Website verantwortlich:</p>
          <br />
          <table>
            <thead>
              <tr>
                <th>Kontakt</th>
                <th>Brigitte Jellinek</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Email</td>
                <td>
                  <a href="mailto:brigitte.jellinek@fh-salzburg.ac.at">
                    brigitte.jellinek@fh-salzburg.ac.at
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <h4>Haftungsausschluss</h4>
          <p>
            Die Zusammenstellung der Informationen auf dieser Website wurde mit
            gr&ouml;&szlig;tm&ouml;glicher Sorgfalt vorgenommen. Dennoch kann
            keinerlei Gew&auml;hr f&uuml;r Aktualit&auml;t, Korrektheit,
            Vollst&auml;ndigkeit oder Qualit&auml;t der bereitgestellten
            Informationen und Daten &uuml;bernommen werden.
            Haftungsanspr&uuml;che gegen die Fachhochschule Salzburg GmbH oder
            die Autoren bzw. Verantwortlichen dieser Website f&uuml;r
            Sch&auml;den materieller oder immaterieller Art, die auf
            fehlerhaften oder unvollst&auml;ndigen Informationen und Daten
            beruhen, sind, soweit nicht Vorsatz oder grobe Fahrl&auml;ssigkeit
            vorliegt, ausgeschlossen. Links zu anderen Webseiten wurden
            sorgf&auml;ltig ausgew&auml;hlt. Da die Fachhochschule Salzburg GmbH
            auf deren Inhalt keinen Einfluss hat, wird die Haftung, soweit
            rechtlich m&ouml;glich, ausgeschlossen.
          </p>
          <p>
            F&uuml;r weitere Informationen besuchen Sie bitte das{' '}
            <a href="http://www.fh-salzburg.ac.at/footer/impressum/">
              Impressum der Fachhochschule Salzburg
            </a>
            .
          </p>
        </div>
      </section>
    </article>
  </Layout>
);

export default ImprintPage;
